import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import Navbar from './components/NavigationBar.vue';


const app = createApp(App);

app.config.globalProperties.$theme = {
    isLightMode: true, // Set the initial theme
};
app.component('Navbar', Navbar); // Register the Navbar component
app.use(router); 
app.mount('#app');
