<template>
  <div id="app" :class="{ 'dark-mode': isDarkMode }">
    <Navbar @toggle-theme="toggleTheme" />
    <router-view />
    <!-- <GlobalFooter /> -->
  </div>
</template>

<script>
import Navbar from './components/NavigationBar.vue'
import GlobalFooter from './components/GlobalFooter.vue';
import Modal from './components/ModalPage.vue';

export default {
  name: "pink-navbar",
  data() {
    return {
      showMenu: false,
      isDarkMode: false,
    }
  },
  components: {
    Navbar,
    GlobalFooter,
    Modal,
  },
  computed: {
    themeClass() {
      return this.$theme.isLightMode ? 'light-bg' : 'dark-bg';
    }
  },
  methods: {
    toggleNavbar: function () {
      this.showMenu = !this.showMenu;
    },
    toggleTheme() {
      document.body.classList.toggle('dark-mode');
    },
  },
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
}
</style>
