<template>
  <nav :class="{
    'bg-transparent': !isScrolled,
    'bg-gradient': isScrolled,
  }" class="navbar bg-transparent text-white p-4 flex justify-between sticky top-0 z-10">
    <div class="flex items-center">
      <div class="brand text-xl font-semibold">
        <a class="navbar-brand nav-link" href="">
          <strong>Kevnlan</strong>
        </a>
      </div>
    </div>
    <div class="hidden md:flex md:items-center">
      <router-link to="/" class="nav-link">Home</router-link>
      <router-link to="/portfolio" class="nav-link">Work</router-link>
      <router-link to="/contact" class="nav-link">Contact</router-link>
    </div>
    <div class="flex items-center">
      <div class="hidden md:flex md:items-center social-links">
        <a href="#" class="text-white hover:text-gray-300">Medium</a>
        <a href="#" class="text-white hover:text-gray-300">Github</a>
        <!-- <button title="Hire Me"
          class="text-md font-general-medium bg-indigo-500 hover:bg-indigo-600 text-white shadow-sm rounded-md px-5 py-2.5 duration-300"
          @click="showModal()" aria-label="Hire Me Button">Hire Me</button> -->
      </div>
      <button @click="isMobileMenuOpen = !isMobileMenuOpen" class="block md:hidden p-2 focus:outline-none ml-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
    </div>
    <div v-if="isMobileMenuOpen" class="md:hidden absolute top-full left-0 right-0 bg-white shadow-md">
      <div class="py-2 px-4">
        <router-link to="/" class="block text-gray-700 hover:bg-gray-100 py-2" @click="isMobileMenuOpen = false">
          Home
        </router-link>
        <router-link to="/portfolio" class="block text-gray-700 hover:bg-gray-100 py-2" @click="isMobileMenuOpen = false">
          Work
        </router-link>
        <router-link to="/contact" class="block text-gray-700 hover:bg-gray-100 py-2" @click="isMobileMenuOpen = false">
          Contact
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import Modal from '../components/ModalPage.vue';
export default {
  name: "Navbar",
  components: { Modal },
  data() {
    return {
      isMobileMenuOpen: false,
      isScrolled: false,
      isModalVisible: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
    toggleTheme() {
      this.$emit('toggle-theme');
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  }
};
</script>

<style>
/* Add your additional styling and Tailwind classes here */
.brand {
  margin-left: 20px;
  /* Add spacing to the brand */
}

.navbar {
  background-color: transparent;
  backdrop-filter: blur(4px);
  /* Adjust the blur amount as needed */
}

.nav-link {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  /* Adjust spacing between nav links */
  font-size: 16px;
  /* Adjust font size */
}

/* Custom background gradient for scrolled state */
.bg-gradient {
  background: rgb(20, 30, 48);
  background: -moz-linear-gradient(132deg, rgba(20, 30, 48, 1) 0%, rgba(36, 59, 85, 1) 82%);
}

.social-links {
  margin-right: 20px;
  /* Add spacing to the social links */
}

/* Responsive styles */
@media (max-width: 767px) {
  .navbar {
    flex-direction: column;
    align-items: center;
  }

  .brand {
    margin: 0;
  }

  .nav-link {
    margin: 10px 0;
  }

  .social-links {
    margin-right: 0;
  }
}
</style>
