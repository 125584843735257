<template>
    <footer class="bg-gray-900 text-white p-4 text-center absolute bottom-0 w-full">
      <p>
        <a href="https://storyset.com/web">Web illustrations by Storyset</a> |
        © {{ currentYear }} Kevin Langat. All rights reserved.
      </p>
    </footer>
  </template>
  
  <script>
  export default {
    computed: {
      currentYear() {
        return new Date().getFullYear();
      },
    },
    mounted() {
      const footer = this.$el.querySelector("footer");
  
      function handleResize() {
        if (window.innerWidth <= 640) {
          // Mobile view
          footer.classList.remove("absolute");
          footer.classList.add("relative");
        } else {
          // Desktop view
          footer.classList.remove("relative");
          footer.classList.add("absolute");
        }
      }
  
      // Run the handleResize function when the page loads
      handleResize();
  
      // Add an event listener to run the handleResize function when the window is resized
      window.addEventListener("resize", handleResize);
    },
  };
  </script>
  
  <style scoped>
  .footer {
    width: 100%;
  }
  
  /* Media query for mobile view */
  @media (max-width: 640px) {
    .footer {
      position: relative;
      /* Use relative positioning for mobile view */
    }
  }
  
  /* Media query for desktop view */
  @media (min-width: 641px) {
    .footer {
      position: absolute;
      bottom: 0;
      /* Use absolute positioning for desktop view */
    }
  }
  </style>
  