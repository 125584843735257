<template>
  <div class="home flex justify-center items-center h-screen pb-100 text-center text-gray-400">
    <div class="content items-center grid md:grid-cols-2 gap-6 md:px-20 px-2 md:py-10 py-2" style="height: 66.67%;"> <!-- Added padding settings -->
      <div class="relative px-4 py-10 bg-transparent text-left md:backdrop-filter md:blur-20">
        <div style="display: flex; align-items: center;">
          <div class="w-10 h-px border border-gray-300"></div>
          <div class="pl-3  text-lg font-normal">Hi, I am Kevin</div>
        </div>
        <div class=" text-5xl md:text-8xl font-normal pt-5 font-vollkorn">Fullstack <br class="hidden md:inline">Software Developer</div>

        <button>Download Resume</button>
      </div>
      <div class="bg-clear p-4">
        <p class="text-center text-gray-300 text-lg md:text-xl bio-summary">
          I specialize in crafting user-friendly and efficient software solutions that seamlessly encompass backend,
          frontend, and mobile development. With a strong foundation in computer science and a fervent dedication to
          staying at the forefront of the latest technologies, I am resolute in delivering high-quality code and
          exceptional user experiences.
        </p>
      </div>
    </div>
    <div class="stack flex justify-center mt-6 pt-10" style="height: 33.33%;">
      <!-- Logo 1 -->
      <img src="@/assets/logo/android-500-filled.svg" class="h-20 mx-5" alt="Android">
      <!-- Logo 2 -->
      <img src="@/assets/logo/flutter-500-filled.svg" class="h-20 mx-5" alt="Flutter">
      <!-- Logo 3 -->
      <img src="@/assets/logo/django-500.svg" class="h-20 mx-5" alt="Django">
      <!-- Logo 4 -->
      <img src="@/assets/logo/vue-black.svg" class="h-20 mx-5" alt="Vue JS">
      <!-- Logo 5 -->
      <img src="@/assets/logo/javascript-500-filled.svg" class="h-20 mx-5" alt="JS">
      <!-- Logo 6 -->
      <img src="@/assets/logo/python-language-logotype.png" class="h-20 mx-5" alt="Python">
    </div>
  </div>

</template>

<script>
export default {
  name: 'HomeView',
  components: {},
};
</script>

<style>
html {
    scroll-behavior: smooth;
}

.home {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pb-100 {
  padding-bottom: 100px;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
</style>
